<template>
  <modal v-if="this.$store.state.player" name="notification-modal" classes="modal" :minWidth="200"
         :minHeight="600"
         :maxWidth="480"
         :scrollable="true"
         :reset="true"
         :adaptive="true"
         width="90%"
         @opened="onOpenModal"
         @closed="onCloseModal"
         height="auto">
    <div class="flex justify-end relative">
      <button @click="hideModal" class="absolute right-0 mr-4 mt-4 z-50">
        <img src="@/assets/times-black.svg" alt="" rel="preload">
      </button>
    </div>
    <div class="flex flex-col relative items-center">
      <template v-if="activeNotification && activeNotification.type == 'league'">
        <div class="flex flex-col items-center py-4 mb-4">
          <h1 class="mt-4 font-semibold  text-success">TEBRİKLER !</h1>
          <h2 class="mb-4" v-if="activeNotification.templateVars && activeNotification.templateVars.week">{{activeNotification.templateVars.week}}. Hafta Ligi bitti!</h2>
          <!-- <img class="mb-4" style="width:98px;height:98px" src="@/assets/icon-win.svg" alt=""> -->
          <template v-if="activeNotification.templateVars && activeNotification.templateVars.position">
            <div class="ribbons__item ribbons__item--bronze pt-7">
              <span class="text-4xl font-semibold">{{activeNotification.templateVars.position}}.</span>
              <canvas id="confetti-canvas" style="width:1px;height:1px"></canvas>
            </div>
            <h4 class="my-4 text-center">sırada bitirdin. Ödüllerin seni bekliyor.</h4>
          </template>
          <template v-else>
            <div class="ribbons__item ribbons__item--bronze pt-7">
              <canvas id="confetti-canvas" style="width:1px;height:1px"></canvas>
            </div>
            <h4 class="my-4 text-center">{{activeNotification.message}}</h4>
          </template>
          <!-- <h2 class="text-center px-4"><span class="text-4xl text-primary">7.</span> sırada bitirdin!</h2> -->
          <div class="flex justify-center mb-4" v-if="rewardDetails.length > 0">
            <div class="flex flex-col items-center mr-3" v-for="(rewardDetail, i) in rewardDetails" :key="`${rewardDetail}-${i}`">
              <div class="flex items-center justify-center" style="width: 36px; height: 36px">
                <img style="max-height:100%" :src="require(`@/assets/store/${rewardDetail.icon}`)" alt="" class="mx-1" v-if="rewardDetail.icon" /> 
              </div>
              <h2>{{rewardDetail.label}}</h2>
            </div>
          </div>
          <button class="btn btn--primary btn--sm" style="width:180px" @click="hideModal">TAMAM</button>
        </div>
      </template>
      <template v-if="activeNotification && activeNotification.type == 'invite'">
        <div class="flex flex-col items-center py-4 mb-4">
          <h1 class="mt-4 font-semibold  text-success">TEBRİKLER !</h1>
          <template>
            <div class="pt-7">
              <canvas id="confetti-canvas" style="width:1px;height:1px"></canvas>
              <img src="@/assets/store/coin-001.svg">
            </div>
            <h4 class="my-4 text-center">{{activeNotification.message}}</h4>
          </template>
          <button class="btn btn--primary btn--sm" style="width:180px" @click="hideModal">TAMAM</button>
        </div>
      </template>
      <template v-if="activeNotification && activeNotification.type == 'warning'">
        <div class="flex flex-col items-center py-4 mb-4">
          <h1 class="mt-4 font-semibold flex items-center"><img src="@/assets/icon-error.svg" style="height:48px" class="mr-2" />  UYARI </h1>
          <p class="mt-4 px-4 text-center text-lg">{{activeNotification.message}}</p>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import {mapActions, mapState} from "vuex";
export default {
  name: "NotificationModal",
  data(){
    return {
      confettiAnim: null,
      confettiCount: 200,
      confettiAnimDefaults: {
        origin: { y: 0.7 }
      },
    }
  },
  computed: {
    ...mapState(["notifications"]),
    activeNotification() {
      return this.notifications && this.notifications.length > 0 ? this.notifications[this.notifications.length - 1] : null
    },
    rewardDetails: function() {
      var details = []
      if(this.activeNotification && this.activeNotification.additionalData && this.activeNotification.additionalData.reward) {
        
        let instructions = this.activeNotification.additionalData.reward;
        let flattenInstructions = {}
        this._.map(instructions, (v, k) => {
          if (k === 'extraInternet') {
                  this._.forEach(v, (pv, pk) => {
                      if (pk === 'title') {
                          flattenInstructions[k] = pv;
                      }
                  });
              } else if('powerups' == k) {
              this._.forEach(v, (pv, pk) => {
                  if(
                      ('immortality' == pk && pv.isActive) || 
                      pv > 0
                      ) {
                      flattenInstructions[pk] = pv
                  }
              })
          }else{
              if(v > 0) {
                  flattenInstructions[k] = v
              }
          }
        })

        this._.forEach(flattenInstructions, (v, k) => {
            var label = v
            var icon = null
            switch(k) {
                case 'giftCard':
                  icon = 'icon-coupon.svg'
                  break;

                case 'coins':
                  icon = 'coin-001.svg'
                  break;

                case 'rewind':
                    icon = 'rewind.svg'
                    break;

                case 'open':
                    icon = 'open.svg'
                    break;

                case 'change':
                    icon = 'skip.svg'
                    break;

                case 'hint':
                    icon = 'hint.svg'
                    break;
                
                case 'ticket':
                    icon = 'ticket-001.svg'
                    break;

                case 'immortality':
                    icon = 'ticket-immortal.svg'
                    label = v.validUntil == '+P7D' ? '7 Gün' : '24'
                    break;

                case 'extraInternet':
                    icon = 'internet.png'
                    break;

                default:
                    icon = null;
                    break;
            }
            
            details.push({icon, label})
          })
        }
        return details
    }
  },
  watch: {
    activeNotification() {
      if(this.activeNotification) {
        this.showModal();
      }
    }
  },
  created() {
    if(this.$store.getters.isAuthenticated) {
      this.getPlayerNotifications().then(() => {
        // console.log(this.activeNotification);
      })
    }
    const confetti = require('canvas-confetti');
    var myCanvas = document.getElementById('canvas');

    this.confettiAnim = confetti.create(myCanvas, {
      resize: true,
      useWorker: true
    });
  },
  methods: {
    ...mapActions(["getPlayerNotifications", "readPlayerNotification"]),
    fireConfettiAnim(particleRatio, opts) {
      return this.confettiAnim(Object.assign({}, this.confettiAnimDefaults, opts, {
        particleCount: Math.floor(this.confettiCount * particleRatio)
      }));
    },
    animateRealisticConfetti(){
      this.fireConfettiAnim(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      this.fireConfettiAnim(0.2, {
        spread: 60,
      });
      this.fireConfettiAnim(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      this.fireConfettiAnim(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      this.fireConfettiAnim(0.1, {
        spread: 120,
        startVelocity: 45,
      })
    },
    showModal(){
      this.$modal.show("notification-modal");
    },
    hideModal() {
      this.$modal.hide("notification-modal")
    },
    onOpenModal() {
      if(this.activeNotification.type == 'league') {
        this.animateRealisticConfetti();
      }
    },
    onCloseModal() {
      this.readPlayerNotification(this.activeNotification);
    }
  },
}
</script>

<style scoped>

</style>